import {
    FETCH_AUTHORS_ERROR,
    FETCH_AUTHORS_PENDING,
    FETCH_AUTHORS_SUCCESS
} from './types';

export function fetchAuthorsSuccess( data ) {
    return {
        type: FETCH_AUTHORS_SUCCESS,
        byId: data.byId,
        allIds: data.allIds
    }
}

export function fetchAuthorsError( error ) {
    return {
        type: FETCH_AUTHORS_ERROR,
        error: error
    }
}
export function fetchAuthorsPending() {
    return {
        type: FETCH_AUTHORS_PENDING
    }
}
