import { FETCH_PAGEFIELDS_ERROR, FETCH_PAGEFIELDS_PENDING, FETCH_PAGEFIELDS_SUCCESS } from '../actions/types';

const initialState = {
    fields: {},
    pending: false,
    fetched: false,
    error: null
}

export default function ( state = initialState, action ){
    switch( action.type ){
    case FETCH_PAGEFIELDS_SUCCESS:
        return {
            ...state,
            fields: action.fields,
            fetched: true,
            pending: false,
            error: null
        }
    case FETCH_PAGEFIELDS_PENDING:
        return {
            ...state,
            fetched: false,
            pending: true,
            error: null
        }
    case FETCH_PAGEFIELDS_ERROR :
        return{
            ...state,
            fetched: false,
            pending: false,
            error: action.error
        }
    default:
        return state;
    }
}