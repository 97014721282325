import { REMOVE_ALL_GANRES,
    FETCH_ALL_GANRES_ERROR,
    FETCH_ALL_GANRES_PENDING,
    FETCH_ALL_GANRES_SUCCESS,
    UPDATE_ONE_GANRE,
    UPDATE_MANY_GANRES
} from '../actions/types';

const initialState = {
    byId: {},
    allIds: [],
    pending : false,
    fetched: false,
    error: null,
    videos:[],
    books:[],
    audios: []
}
let oldGanre;
let newGanre;
export default function ( state = initialState, action ){
    switch( action.type ){
    case FETCH_ALL_GANRES_SUCCESS:
        return {
            ...state,
            byId: action.ganres,
            allIds: action.allIds,
            books: action.books,
            audios: action.audios,
            videos: action.videos,
            fetched: true,
            pending: false,
            error: null
            
        }
    case FETCH_ALL_GANRES_PENDING: 
        return {
            ...state,
            fetched: false,
            pending: true,
            error: null
        }
    case UPDATE_ONE_GANRE:
        newGanre = action.ganre;
        oldGanre = state.ganres.byId[ action.ganre.id ];
        return {
            ...state,
            byId:{
                [ newGanre.id ]:{
                    ...oldGanre, newGanre
                }
            }
        }
    case REMOVE_ALL_GANRES:
        return{
            ...state,
            byId: {},
            allIds: [],
            pending : false,
            fetched: false,
            error: null
        }
    case FETCH_ALL_GANRES_ERROR:
        return{
            ...state,
            pending : false,
            fetched: false,
            error: action.error
        }
    case UPDATE_MANY_GANRES:
        Object.keys( action.data ).forEach( ( k )=>{
            if( state.byId[ k ].bookIds && action.data[ k ].bookIds ){
                const bookIds = [ ...new Set( [ ...state.byId[ k ].bookIds,...action.data[ k ].bookIds ] ) ]
                state.byId[ k ] = { ...state.byId[ k ],...action.data[ k ] }
                state.byId[ k ].bookIds = bookIds
            } else if( state.byId[ k ].videoIds && action.data[ k ].videoIds ){
                const videoIds = [ ...new Set( [ ...state.byId[ k ].videoIds,...action.data[ k ].videoIds ] ) ]
                state.byId[ k ] = { ...state.byId[ k ],...action.data[ k ] }
                state.byId[ k ].videoIds = videoIds
            } else if( state.byId[ k ].audioIds && action.data[ k ].audioIds ) {
                const audioIds = [ ...new Set( [ ...state.byId[ k ].audioIds,...action.data[ k ].audioIds ] ) ]
                state.byId[ k ] = { ...state.byId[ k ],...action.data[ k ] }
                state.byId[ k ].audioIds = audioIds
            } else {
                state.byId[ k ] = { ...state.byId[ k ],...action.data[ k ] }
            }
        } )
        return state
    default:
        return state;
    }
}