import { 
    FETCH_ALL_AUDIOS_ERROR,
    FETCH_ALL_AUDIOS_SUCCESS,
    FETCH_ALL_AUDIOS_PENDING,
    SET_LISTENING
} from '../actions/types';

//allEpisodes ={ number: [url, title, descriptive title]}
const initialState = {
    byId: {},
    allIds: [],
    error: null,
    episodic:[],
    nonEpisodic: [],
    pending: false,
    isFetched: false,
    listening: {
        allEpisodes: {},
        id: null,
        episodeNumber: null,
        isPlaying: false
    }
}
export default function( state = initialState, action ) {

    switch ( action.type ) {

    case FETCH_ALL_AUDIOS_SUCCESS:
        return {
            ...state,
            byId: { ...state.byId,...action.byId }, 
            allIds: [ ...new Set( [ ...state.allIds,...action.allIds ] ) ],
            episodic: [ ...new Set( [ ...state.episodic,...action.episodic ] ) ],
            nonEpisodic: [ ...new Set( [ ...state.nonEpisodic,...action.nonEpisodic ] ) ],
            pending: false,
            isFetched: true
        };
    case FETCH_ALL_AUDIOS_ERROR:
        return {
            ...state,
            error: action.error,
            pending: false,
            isFetched: true
        };
    case FETCH_ALL_AUDIOS_PENDING:
        return {
            ...state,
            pending: true,
            isFetched: false
        }
    case SET_LISTENING: 
        return {
            ...state,
            listening: action.listening
        }
    default:
        return state;
    }
}