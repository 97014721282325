import {
    FETCH_ALL_AUDIOS_ERROR,
    FETCH_ALL_AUDIOS_SUCCESS,
    FETCH_ALL_AUDIOS_PENDING,
    SET_LISTENING
} from './types';

export function fetchAudiosSuccess( data ) {
    return {
        type: FETCH_ALL_AUDIOS_SUCCESS,
        byId: data.byId,
        allIds: data.allIds,
        episodic: data.episodic,
        nonEpisodic: data.nonEpisodic
    }
}

export function fetchAudiosError( error ) {
    return {
        type: FETCH_ALL_AUDIOS_ERROR,
        error: error
    }
}
export function fetchAudiosPending() {
    return {
        type: FETCH_ALL_AUDIOS_PENDING
    }
}

export function setListening ( audio ){
    return {
        type: SET_LISTENING,
        listening: audio
    }
}