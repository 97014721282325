import { 
    FETCH_USERITEMS_ERROR,
    FETCH_USERITEMS_PENDING,
    FETCH_USERITEMS_SUCCESS,
    REMOVE_USERITEMS,
    UPDATE_LIBRARY
} from './types';

export function fetchUserItemsSuccess ( items ){
    return {
        type: FETCH_USERITEMS_SUCCESS,
        items: items
    }
}

export function fetchUserItemsError ( error ){
    return {
        type: FETCH_USERITEMS_ERROR,
        error: error
    }
}

export function fetchUserItemsPending (){
    return {
        type: FETCH_USERITEMS_PENDING
    }
}
export function updateLibrary ( library ){
    return{
        type: UPDATE_LIBRARY,
        library: library.library,
        bookIds: library.bookIds,
    }
}
export function removeUserItems (){
    return {
        type: REMOVE_USERITEMS
    }
}