import React from 'react';
import firebase from 'firebase/app';
import * as firebaseConfig from '../config/firebase-config.json'
import { idToken, user } from 'rxfire/auth';
import 'firebase/auth';
import store from '../store/store';
import {
    fetchUserError,
    fetchUserPending,
    fetchUserSuccess,
    removeUser
} from '../store/actions/user-actions';
import internalAxios from '../api/utilities/axios-instances';
import { removeUserItems } from '../store/actions';
const config = firebaseConfig.default;

export const FirebaseContext = React.createContext( null );
export class Firebase{
    constructor() {
        if( firebase.apps.length === 0 ){
            firebase.initializeApp( config );
        }
        this.auth = firebase.auth();
        if( process.env.NODE_ENV === 'development' ){
            this.auth.useEmulator( 'http://localhost:9099' );
        }
        this.providers = {
            google: new firebase.auth.GoogleAuthProvider(),
            twitter: new firebase.auth.TwitterAuthProvider(),
            facebook: new firebase.auth.FacebookAuthProvider(),
            microsoft: new firebase.auth.OAuthProvider( 'microsoft.com' )
        }
    }
      
      doCreateUserWithEmailAndPassword = ( email, password ) =>
          this.auth.createUserWithEmailAndPassword( email, password );
     
      doSignInWithEmailAndPassword = ( email, password ) =>
          this.auth.signInWithEmailAndPassword( email, password );
     
      doSigInWithPopup = provider=>
          this.auth.signInWithPopup( provider );
      
      doSignOut = () => this.auth.signOut();
     
      doUpdateProfile = params =>
          this.auth.currentUser.updateProfile( params );

      doFetchSignInMethodForEmail = email =>
          this.auth.fetchSignInMethodsForEmail( email );

      doPasswordReset = email => this.auth.sendPasswordResetEmail( email );
     
      doPasswordUpdate = password =>
          this.auth.currentUser.updatePassword( password );

      doSendVerificationEmail = ()=>
          this.auth.currentUser.sendEmailVerification();
}

export const firebaseObserve = async( app )=> {
    try {
        const auth = app.auth;
        store.dispatch( fetchUserPending() )
        idToken( auth ).subscribe( token => { 
            if( token ){
                internalAxios.defaults.headers.common[ 'Authorization' ] = 'Bearer ' + token;
            }
        } );
        user( auth ).subscribe( u => {
            if( u ){
                const userData = {
                    displayName:u.displayName,
                    email: u.email,
                    emailVerified: u.emailVerified,
                    uid: u.uid,
                    isAnonymous: u.isAnonymous,
                    metadata: u.metadata
                }
                store.dispatch( fetchUserSuccess( userData ) )
            } else {
                store.dispatch( removeUser() )
                store.dispatch( removeUserItems() )
            }
        } );
    
    } catch ( error ) {
        store.dispatch( fetchUserError( error ) )
    }

}