import store from '../store/store';
import { getAllGanresForStore, getPageFields } from '../api';
import { setIsloadingError, setIsloadingFalse, setIsloadingTrue } from '../store/actions/isloading-actions';

const storeState = store.getState()

const init = ()=>{
    store.dispatch( setIsloadingTrue() )
    if( storeState.ganres.fetched && storeState.pageFields.fetched ){
        return store.dispatch( setIsloadingFalse() )
    }
    
    return Promise.all( [
        store.dispatch( getAllGanresForStore() ),
        store.dispatch( getPageFields( { 'category': 'web' } ) )
    ] ).then( ( [ ganres, pageFields ] )=>{
        if( ganres, pageFields ){
            store.dispatch( setIsloadingFalse() )
        }
    } ).catch( err=>{
        store.dispatch( setIsloadingError( err ) )
    } )
}
export default init
