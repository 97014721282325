import { EDITORIALS_URL } from '../../endpoints';
import internalAxios from '../../utilities/axios-instances';
import {
    fetchEditorialsError,
    fetchEditorialsPending,
    fetchEditorialsSuccess
} from '../../../store/actions';
import {
    normalizeEditorials
} from '../normalize-and-functions';

const getEditorialsForStore = ( data )=>{
    return async( dispatch )=>{
        const query = data? data: {};
        const config = {
            method: 'GET',
            url: `${ EDITORIALS_URL }`,
            responseType: 'json',
            params: query
        }
        try {
            dispatch( fetchEditorialsPending() );
            const result = await internalAxios( config )
            if( result.data.success ){
                const finalResult = await normalizeEditorials( result.data.data );
                dispatch( fetchEditorialsSuccess( finalResult ) )
                return true
            }
            return false
        } catch ( error ) {
            dispatch( fetchEditorialsError( error ) )
            return false
        }
    }
}
export default getEditorialsForStore;