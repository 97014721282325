import { SET_ISLOADING_ERROR, SET_ISLOADING_FALSE, SET_ISLOADING_TRUE } from './types';

export function setIsloadingTrue( ) {
    return {
        type: SET_ISLOADING_TRUE,
    }
}

export function setIsloadingFalse( ) {
    return {
        type: SET_ISLOADING_FALSE
    }
}

export function setIsloadingError( err ) {
    return {
        type: SET_ISLOADING_ERROR,
        error: err
    }
}