import { FETCH_PUBLISHERS_ERROR, 
    FETCH_PUBLISHERS_SUCCESS,
    FETCH_PUBLISHERS_PENDING
} from '../actions/types';

const initialState = {
    byId: {},
    allIds: [],
    error: null,
    pending: false
}
export default function( state = initialState, action ) {
    switch ( action.type ) {

    case FETCH_PUBLISHERS_SUCCESS:
        return {
            ...state,
            byId: { ...state.byId,...action.byId }, 
            allIds: [ ...new Set( [ ...state.allIds,...action.allIds ] ) ],
            pending: false
        };
    case FETCH_PUBLISHERS_ERROR:
        return {
            ...state,
            error: action.error,
            pending: false
        };
    case FETCH_PUBLISHERS_PENDING:
        return {
            ...state,
            pending: true
        }
    default:
        return state
    }
}