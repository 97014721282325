import { schema, normalize } from 'normalizr';

export const normalizeAudiosForStore = ( audios )=>{
    const audioSchema = new schema.Entity( 'audios', {
    }, { idAttribute: '_id' } )
    const res = normalize( audios, [ audioSchema ] )
    return res;
}

export const groupIdsByGanreAndClass = ( audios )=>{
    const forGanres = {};
    const forClass = { episodic:[], nonEpisodic:[] }
    audios.forEach( element => {
        if( !forGanres[ element.mainGanre ] ){
            forGanres[ element.mainGanre ] = { audioIds:[] }
        } 
        if( element.class ){
            forClass.episodic.push( element._id )
        } else {
            forClass.nonEpisodic.push( element._id )
        }
        forGanres[ element.mainGanre ].audioIds.push( element._id )
    } );
    return { forClass, forGanres }
}