import { 
    FETCH_PAGEFIELDS_ERROR,
    FETCH_PAGEFIELDS_PENDING,
    FETCH_PAGEFIELDS_SUCCESS
} from './types';

export function fetchPageFieldsSuccess ( pagefields ){
    return {
        type: FETCH_PAGEFIELDS_SUCCESS,
        fields: pagefields.fields
    }
}

export function fetchPageFieldsError ( error ){
    return {
        type: FETCH_PAGEFIELDS_ERROR,
        error: error
    }
}

export function fetchPageFieldsPending (){
    return {
        type: FETCH_PAGEFIELDS_PENDING
    }
}