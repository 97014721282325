import { FETCH_VIDEOS_ERROR, 
    FETCH_VIDEOS_SUCCESS,
    FETCH_VIDEOS_PENDING
} from '../actions/types';

const initialState = {
    byId: {},
    allIds: [],
    error: null,
    episodic:[],
    nonEpisodic: [],
    featured: [],
    pending: false,
    isFetched: false
}
export default function( state = initialState, action ) {

    switch ( action.type ) {

    case FETCH_VIDEOS_SUCCESS:
        return {
            ...state,
            byId: { ...state.byId,...action.byId }, 
            allIds: [ ...new Set( [ ...state.allIds,...action.allIds ] ) ],
            episodic: [ ...new Set( [ ...state.episodic,...action.episodic ] ) ],
            featured: [ ...new Set( [ ...state.featured,...action.featured ] ) ],
            nonEpisodic: [ ...new Set( [ ...state.nonEpisodic,...action.nonEpisodic ] ) ],
            pending: false,
            isFetched: true
        };
    case FETCH_VIDEOS_ERROR:
        return {
            ...state,
            error: action.error,
            pending: false,
            isFetched: true
        };
    case FETCH_VIDEOS_PENDING:
        return {
            ...state,
            pending: true,
            isFetched: false
        }
    default:
        return state;
    }
}
