import React from 'react';
import style from './image-displayer.module.scss';
import PropTypes from 'prop-types';

function ImageDisplayer ( props ) {

    function imageClicked () {

        if ( props.imageClicked ) {

            return props.imageClicked();

        }

    }
    return (
        <div className={ props.style && props.style.container ? props.style.container : style.container }>
            <img
                alt=""
                className={ props.style && props.style.image ? props.style.image : style.image }
                onClick={ ( e ) => imageClicked( e ) }
                src={ props.src }
            />
        </div>
    )

}

ImageDisplayer.propTypes = {
    'imageClicked': PropTypes.func,
    'style': PropTypes.object,
    'src': PropTypes.oneOfType( [
        PropTypes.string,
        PropTypes.object
    ] )

}

export default ImageDisplayer;
