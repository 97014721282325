import { AUTHORS_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';
import {
    fetchAuthorsSuccess,
    fetchAuthorsError,
    fetchAuthorsPending
} from '../../store/actions/';
import {
    normalizeAuthors
} from './normalize-and-functions';

const getAndStoreAuthor = ( id ) => {

    return async( dispatch )=>{
        try {
            const config = {
                method: 'GET',
                url: `${ AUTHORS_URL }/${ id }`,
                responseType: 'json'
            }
            dispatch( fetchAuthorsPending() )
            const data = await internalAxios( config )
            if( data.data.success ){
                const finalData = normalizeAuthors( [ data.data.data ] )
                dispatch( fetchAuthorsSuccess( {
                    byId: finalData.entities.authors,
                    allIds: finalData.result } ) )
                return true
            }
            dispatch( fetchAuthorsError( 'no-data' ) )
            return null
        } catch ( err ) {
            dispatch( fetchAuthorsError( err ) )
            return null
        }
    }
}
export default getAndStoreAuthor;