import { AUDIOS_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';
import {
    fetchAudiosError,
    fetchAudiosSuccess, 
    fetchAudiosPending,
    updateManyGanre
} from '../../store/actions';
import { normalizeAudiosForStore, groupIdsByGanreAndClass } from './normalize-data';

const getAllAudiosAndStore = ( queryData ) => {
    return async( dispatch )=>{
        dispatch( fetchAudiosPending() )
        const query = queryData? queryData: {
        };
        const config = {
            method: 'GET',
            url: `${ AUDIOS_URL }`,
            params: query,
            responseType: 'json'
        }
        try {
            const data = await internalAxios( config )
            if( data.data.success ){
                const res = await normalizeAudiosForStore( data.data.data )
                const resGanresAndClass = await groupIdsByGanreAndClass( data.data.data );
                await dispatch( fetchAudiosSuccess( { allIds: res.result,
                    byId: res.entities.audios, 
                    episodic: resGanresAndClass.forClass.episodic, 
                    nonEpisodic:resGanresAndClass.forClass.nonEpisodic } ) )
                dispatch( updateManyGanre( resGanresAndClass.forGanres ) )
                return res.result;
            }
            return null
        } catch ( err ) {
            dispatch( fetchAudiosError( err ) )
            return null
        }
    }
}
export default getAllAudiosAndStore;