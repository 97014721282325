import { REMOVE_ALL_GANRES,
    FETCH_ALL_GANRES_ERROR,
    FETCH_ALL_GANRES_PENDING,
    FETCH_ALL_GANRES_SUCCESS,
    UPDATE_ONE_GANRE,
    UPDATE_MANY_GANRES
} from './types';

export function fetchAllGanresSuccess( data ) {
    return {
        type: FETCH_ALL_GANRES_SUCCESS,
        ganres: data.ganres,
        allIds: [ ...new Set( data.allIds ) ],
        videos: data.video? data.video: [],
        books: data.kitap? data.kitap:[],
        audios: data.audio? data.audio:[]
    }
}

export function fetchAllGanresPending( ) {
    return {
        type: FETCH_ALL_GANRES_PENDING
    }
}

export function fetchAllGanresError( error ) {
    return {
        type: FETCH_ALL_GANRES_ERROR,
        error: error
    }
}

export function removeGanres( ) {
    return {
        type: REMOVE_ALL_GANRES,
        ganres: {}
    }
}

export function updateOneGanre ( ganre ){
    return {
        type: UPDATE_ONE_GANRE,
        ganre: ganre
    }
}

export function updateManyGanre ( data ){
    return {
        type: UPDATE_MANY_GANRES,
        data: data
    }
}
