import { combineReducers } from 'redux';
import isLoading from './isloading';
import books from './books';
import user from './user';
import ganres from './ganres';
import pageFields from './page-fields'
import publishers from './publishers';
import authors from './authors';
import editorials from './editorials';
import userItems from './user-items';
import videos from './videos';
import audios from './audios';
import series from './series';
export default combineReducers( { 
    isLoading,
    books,
    user,
    ganres,
    pageFields,
    publishers,
    authors,
    editorials,
    userItems,
    videos,
    audios,
    series
} );
