import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Card from 'react-bootstrap/Card'
import style from './book-display.module.scss';
import { assetsUrl } from '../../../../api/endpoints';
import AddtoLibOrDown from '../../add-to-library-or-download/add-to-library-or-download';
import PropTypes from 'prop-types';

const BookDisplay = ( { item, addButton, downloadButton } )=>{
    const { authors, books } = useSelector( state=>state )
    const [ quickAdd, setQuickAdd ] = useState( false )
    const navigate = useNavigate();

    function handleQuickAdd( val ){
        if( addButton||downloadButton ){
            setQuickAdd( val )
        }
    }
   
    return(
        <>
            {
                !books.byId[ item ]? 
                    <Card className={ style.card }>
                        <Skeleton variant="rect" className={ style.skeleton }/>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Card>
                    :
                    <Card className={ style.card }
                        onMouseLeave={ ()=> handleQuickAdd( false ) }>
                        {books.byId[ item ].images?
                            <Card.Img variant="top" src={ `${ assetsUrl }${ books.byId[ item ].images[ 0 ] }` } 
                                className={ style.image }
                                onClick={ ()=> navigate( `/kitaplar/k/${ books.byId[ item ]._id }/${ authors.byId[ books.byId[ item ].author ].name.replace( / /g,'-' ) }/${ books.byId[ item ].title.replace( / /g,'-' ) }` ) }
                                onMouseEnter={ ( ()=> handleQuickAdd( true ) ) } />:
                            <Skeleton variant="rect" className={ style.skeleton }/>
                        }
                        <Card.Body>
                            {quickAdd&&
                            <div className={ style.buttons_overlays }>
                                <AddtoLibOrDown addButton={ addButton } downloadButton={ downloadButton }
                                    style={ { ...{ downloadButton:style.downloadButton, addToButton:style.addToButton } } }
                                    book={ books.byId[ item ] } />
                            </div>
                            }
                            <Card.Title
                                className={ style.title } onClick={ ()=> navigate( `/kitaplar/k/${ books.byId[ item ]._id }/${ authors.byId[ books.byId[ item ].author ].name.replace( / /g,'-' ) }/${ books.byId[ item ].title.replace( / /g,'-' ) }` ) }>{books.byId[ item ].title}</Card.Title>

                            {
                                books.byId[ item ].author?
                                    <Card.Text className={ style.author }
                                        onClick={ ()=> navigate( `/kitaplar/y/${ books.byId[ item ].author }/${ authors.byId[ books.byId[ item ].author ].name.replace( / /g,'-' ) }` ) }>
                                        { authors.byId[ books.byId[ item ].author ].name}
                                    </Card.Text>: <Card.Text className={ style.author }
                                        onClick={ ()=> navigate( `/kitaplar/y/${ books.byId[ item ].author }` ) }>
                                        Not Found
                                    </Card.Text>
                            }
                        </Card.Body>
                    </Card>
            }
        </>
        
    )
}

BookDisplay.propTypes = {
    item: PropTypes.string,
    addButton: PropTypes.bool,
    downloadButton: PropTypes.bool
}
BookDisplay.defaultProps = {
    item: '',
    addButton: true,
    downloadButton: true

}
export default BookDisplay;