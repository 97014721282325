import React from 'react';
import style from './navigations.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BookIcon from '@mui/icons-material/Book';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { toSignOut } from '../../api'
import PropTypes from 'prop-types';
import { LoadingGlobal } from '../../components/reusables/';

function Navigation( { location } ) {
    const { isFetched, isPending } = useSelector( state => state.user )
    const navigate = useNavigate();

    return (
        <Container 
            bsPrefix={ location==='izle'? style.nav_videos:
                location==='dinle'? style.nav_audios: style.nav_general }>
            <Row bsPrefix={ style.top_nav_bar }>
                <Col
                    bsPrefix={ style.logo }
                    onClick={ () => navigate( '/' ) }>
                </Col>
                <Col bsPrefix={ style.left_nav }>
                    <span className={ location === 'kitaplar'?style.active: style.link }
                        onClick={ () => navigate( 'kitaplar' ) }
                    ><BookIcon /> OKU</span>
                    <span className={ location === 'dinle'?style.active: style.link }
                        onClick={ () => navigate( 'dinle' ) }
                    > <RadioButtonCheckedIcon/> <span>DİNLE</span></span>
                    <span className={ location === 'izle'?style.active: style.link }
                        onClick={ () => navigate( 'izle' ) }><LiveTvIcon/> İZLE</span>
                </Col>
                <Col className={ style.account_wrapper }>
                    {isPending? 
                        <LoadingGlobal />: 
                        !isFetched ?
                            <div className={ style.buttons }>
                                <div className={ style.login }
                                    onClick={ () => navigate( '/giris-yap' ) }
                                >
                                    Giriş Yap
                                </div>
                                <div className={ style.register }
                                    onClick={ () => navigate( '/uyelik' ) }
                                >
                                    Ücrestsiz Dene
                                </div>
                            </div> :
                            <div className={ style.buttons }>
                                <div className={ style.library }
                                    onClick={ () => navigate( '/kutuphane' ) }
                                >
                                    Kütüphanem
                                </div>
                                <div className={ style.library }
                                    onClick={ () => navigate( '/hesabim' ) }
                                >
                                    <AccountCircleOutlinedIcon/>
                                </div>
                                <div className={ style.library }
                                    onClick={ ()=>toSignOut( '/', navigate ) }
                                >
                                    <ExitToAppOutlinedIcon />
                                </div>
                            </div>
                    }
                </Col>
            </Row>
        </Container>
    );
}
Navigation.propTypes = {
    location: PropTypes.string
}
export default Navigation;