import { schema, normalize } from 'normalizr';

export const normalizeForBookStore = ( books )=>{
    const author = new schema.Entity( 'authors', {}, { idAttribute: '_id' } );
    const publisher = new schema.Entity( 'publishers', {}, { idAttribute: '_id' } )
    const serie = new schema.Entity( 'serie', {}, { idAttribute: '_id' } )
    const bookSchema = new schema.Entity( 'books', {
        author: author,
        publisher: publisher,
        serie: serie
    }, { idAttribute: '_id' } )
    const res = normalize( books, [ bookSchema ] )
    return res;
}

export const groupIdsByGanreAuthorAndSerie = ( books )=>{
    const byGanre = {};
    const byAuthor = {};
    const bySerie = {}
    books.forEach( element => {
        if( element.mainGanre && !byGanre[ element.mainGanre ] ){
            byGanre[ element.mainGanre ] = { bookIds:[] }
        } 
        if( element.author && !byAuthor[ element.author._id ] ){
            byAuthor[ element.author._id ] = { ...element.author,...{ bookIds:[] } }
        } 
        if( element.serie ){
            if( !bySerie[ element.serie._id ] ){
                bySerie[ element.serie._id ] = { ...element.serie,...{ bookIds:[] } }
            }
            bySerie[ element.serie._id ].bookIds.push( element._id )
        }
        byGanre[ element.mainGanre ].bookIds.push( element._id )
        byAuthor[ element.author._id ].bookIds.push( element._id )
    } );
    return { byGanre, byAuthor, bySerie }
}