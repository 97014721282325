import { useState, useEffect } from 'react';

function useKeyPress( targetKey, shouldPrevent ) {
    const [ keyPressed, setKeyPressed ] = useState( false );

    useEffect( () => {
        const upHandler = ( e ) => {
            if( shouldPrevent ) {e.preventDefault()}
            if ( e.key === targetKey ) {
                setKeyPressed( false );
            }
        };
        function downHandler( e ) {
            if( shouldPrevent ) {e.preventDefault()}
            if ( e.key === targetKey ) {
                setKeyPressed( true );
            }
        }
        window.addEventListener( 'keydown', downHandler );
        window.addEventListener( 'keyup', upHandler );
        return () => {
            window.removeEventListener( 'keydown', downHandler );
            window.removeEventListener( 'keyup', upHandler );
        };
    }, [ shouldPrevent, targetKey ] );
    return keyPressed;
}
export default useKeyPress;