import React from 'react';
import style from './layouts.module.scss';
import { Outlet } from 'react-router-dom';
import BannerGlobal from '../banner-global/banner-global';
import Navigation from '../navigations/navigations';
import { DarkFooter, LightFooter } from '../footer/footer';
function MainLayout ( ) {
    return (
        <div className={ style.main_container }>
            <Navigation/>
            <BannerGlobal />
            <Outlet/>
            <LightFooter />
        </div>
    );
}

function FullLayout ( ) {
    return (
        <div className={ style.container }>
            <Outlet/>
        </div>
    );
}
function VideosLayout (){
    return (
        <div className={ style.videos_container }>
            <Navigation location="izle"/>
            <Outlet/>
            <DarkFooter />
        </div>
    )
}
function AudioLayout (){
    return (
        <div className={ style.main_container }>
            <Navigation location="dinle"/>
            <BannerGlobal />
            <Outlet/>
            <LightFooter />
        </div>
    )
}
function BooksLayout (){
    return (
        <div className={ style.main_container }>
            <Navigation location="kitaplar"/>
            <BannerGlobal />
            <Outlet/>
            <LightFooter />
        </div>
    )
}
export {
    MainLayout,
    FullLayout,
    VideosLayout,
    AudioLayout,
    BooksLayout
}