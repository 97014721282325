import React from 'react';
import { BookDisplay, CarouselReusable } from '..';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
function CarouselBookDisplay( { index, shelveLength, items, style } ){
    return(
        <CarouselReusable { ...{ shelveLength:shelveLength, index:index } }>
            { Array.isArray( items )?
                items.map( ( e,i )=>{
                    return <Col className={ style } key={ i.toString() }>
                        <BookDisplay { ...{ item:e } } />
                    </Col>
                } ): Object.keys( items ).map( ( e,i )=>{
                    return <Col className={ style } key={ i.toString() }>
                        <BookDisplay { ...{ item:items[ e ].id } } />
                    </Col>
                } )
            }
        </CarouselReusable>
    )
}
CarouselBookDisplay.propTypes = {
    items: PropTypes.oneOfType( [
        PropTypes.array,
        PropTypes.object
    ] ),
    index: PropTypes.number,
    shelveLength: PropTypes.number,
    style: PropTypes.string
}
CarouselBookDisplay.defaultProps = {
    starRequired: true
}
export default CarouselBookDisplay;