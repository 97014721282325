import { VIDEOS_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';
import {
    fetchVideosSuccess,
    fetchVideosError,
    fetchVideosPending,
    updateManyGanre
} from '../../store/actions';
import { normalizeVideosForStore, groupIdsByGanreAndClass } from './normalize-and-functions';

const getAllVideosAndStore = ( queryData, isFeatured ) => {
    return async( dispatch )=>{
        try {
            dispatch( fetchVideosPending() )
            const query = queryData? queryData: {
            };
            const config = {
                method: 'GET',
                url: `${ VIDEOS_URL }`,
                params: query,
                responseType: 'json'
            }
            const data = await internalAxios( config )
            if( data.data.success ){
                const res = await normalizeVideosForStore( data.data.data )
                const resGanresAndClass = await groupIdsByGanreAndClass( data.data.data );
                await dispatch( fetchVideosSuccess( { allIds: res.result,
                    byId: res.entities.videos,
                    featured: isFeatured? res.result.slice( 0,25 ): [], 
                    episodic: resGanresAndClass.forClass.episodic, 
                    nonEpisodic:resGanresAndClass.forClass.nonEpisodic } ) )
                dispatch( updateManyGanre( resGanresAndClass.forGanres ) )
                return res.result;
            }
            return null
        } catch ( err ) {
            dispatch( fetchVideosError( err ) )
            return null
        }
    }
}
export default getAllVideosAndStore;