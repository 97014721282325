import React, { useEffect } from 'react';
import style from './play-forward-backward.module.scss';
import Replay10Icon from '@mui/icons-material/Replay10';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Forward10Icon from '@mui/icons-material/Forward10';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import { useKeyPress } from '../..';

function PlayForwardBackward( props ){
    const arrowLeftPressed = useKeyPress( 'ArrowLeft' );
    const arrowRightPressed = useKeyPress( 'ArrowRight' );
    function handlePlay(){
        props.isPlaying?props.player.pause():props.player.play()
    }
    function handleForwardReplay( val ){
        val? props.player.currentTime = props.player.currentTime+10:props.player.currentTime = props.player.currentTime-10
    }
    useEffect( ()=>{
        if( arrowRightPressed ){
            props.player.currentTime = props.player.currentTime+10
        } else if( arrowLeftPressed ){
            props.player.currentTime = props.player.currentTime-10
        }
    },[
        arrowLeftPressed, arrowRightPressed, props.player
    ] )
    return (
        <>
            <Row className={ style.wrapper }>
                <Col className={ style.small_but_cont }>
                    <SkipPreviousIcon 
                        onClick={ props.previousAndNext.previous? props.handlePrevious: ()=>{return} }
                        className={ props.previousAndNext.previous? style.icon: style.icons_inactive }/></Col>
                <Col className={ style.small_but_cont }><Replay10Icon onClick={ ()=>handleForwardReplay( false ) } className={ style.icon } /></Col>
                <Col 
                    onClick={ handlePlay }
                    className={ style.play_pause_icon_container }>
                    <div className={ style.circle_background_for_play_icon }>
                        {
                            !props.isPlaying? <PlayCircleOutlineIcon classes={ { root:style.icon } }/>:<PauseCircleOutlineIcon classes={ { root:style.icon } } /> 
                        }
                    </div>
                </Col>
                <Col className={ style.small_but_cont }><Forward10Icon onClick={ ()=>handleForwardReplay( true ) } className={ style.icon } /></Col>
                <Col className={ style.small_but_cont }>
                    <SkipNextIcon 
                        onClick={ props.previousAndNext.next? props.handleNext: ()=>{return} }
                        className={ props.previousAndNext.next? style.icon: style.icons_inactive } 
                    /></Col>
            </Row>
        </>
    )
}
PlayForwardBackward.propTypes = {
    player: PropTypes.object,
    isPlaying: PropTypes.bool,
    previousAndNext: PropTypes.object,
    handleNext: PropTypes.func,
    handlePrevious: PropTypes.func
}
export default PlayForwardBackward;