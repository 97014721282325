import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import style from './style.module.scss';
function NavLoading (){
    return (
        <div className={ style.nav_loading }>
            <Skeleton variant="rect" animation="wave" className={ style.rect }/>
        </div>
    )
}
export default NavLoading;