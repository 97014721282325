import { DOCS_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';

const getDoc = async( query ) => {

    const config = {
        method: 'GET',
        url: `${ DOCS_URL }`,
        params: query,
        responseType: 'json'
    }
    try {
        const data = await internalAxios( config )
        if( data.data.success ){
            return data.data.data
        }
        return
    } catch ( err ) {
        return err
    }
}
export default getDoc;
