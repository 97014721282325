import { FETCH_AUTHORS_ERROR, 
    FETCH_AUTHORS_SUCCESS,
    FETCH_AUTHORS_PENDING
} from '../actions/types';

const initialState = {
    byId: {},
    allIds: [],
    error: null,
    pending: false
}
export default function( state = initialState, action ) {

    switch ( action.type ) {

    case FETCH_AUTHORS_SUCCESS:
        Object.keys( action.byId ).forEach( e=>{
            if( state.byId[ e ] && state.byId[ e ].bookIds ){
                const ids = action.byId[ e ].bookIds? action.byId[ e ].bookIds:[]
                const bookIds = [ ...new Set( [ ...state.byId[ e ].bookIds,...ids ] ) ]
                action.byId[ e ].bookIds = bookIds
            } 
        } )
        return {
            ...state,
            byId: { ...state.byId,...action.byId }, 
            allIds: [ ...new Set( [ ...state.allIds,...action.allIds ] ) ],
            pending: false
        };
    case FETCH_AUTHORS_ERROR:
        return {
            ...state,
            error: action.error,
            pending: false
        };
    case FETCH_AUTHORS_PENDING:
        return {
            ...state,
            pending: true
        }
    default:
        return state;
    }
}