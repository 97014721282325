import{
    FETCH_EDITORIALS_PENDING,
    FETCH_EDITORIALS_SUCCESS,
    FETCH_EDITORIALS_ERROR
} from './types';

export function fetchEditorialsSuccess( data ) {
    return {
        type: FETCH_EDITORIALS_SUCCESS,
        byItemId: data.entities.editorials,
        allIds: data.result
    }
}

export function fetchEditorialsError( error ) {
    return {
        type: FETCH_EDITORIALS_ERROR,
        error: error
    }
}
export function fetchEditorialsPending() {
    return {
        type: FETCH_EDITORIALS_PENDING
    }
}