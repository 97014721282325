import { 
    FETCH_USERITEMS_ERROR,
    FETCH_USERITEMS_PENDING,
    FETCH_USERITEMS_SUCCESS,
    REMOVE_USERITEMS,
    UPDATE_LIBRARY
} from '../actions/types';

const initialState = {
    bookIds: [],
    libraryById: {},
    membership: {},
    expiredSubscriptions: [],
    address: {},
    id: '',
    error: null,
    pending: false,
    fetched: false
}
export default function( state = initialState, action ) {

    switch ( action.type ) {

    case FETCH_USERITEMS_SUCCESS:
        return {
            ...state,
            libraryById: { ...state.libraryById,...action.items.libraryById }, 
            bookIds: [ ...new Set( [ ...state.bookIds,...action.items.bookIds ] ) ],
            address: { ...state.address,...action.items.address },
            membership: { ...state.membership,...action.items.membership },
            expiredSubscriptions: [ ...new Set( [ ...state.expiredSubscriptions,...action.items.expiredSubscriptions ] ) ],
            id: action.items.id,
            pending: false,
            error: null,
            fetched: true
        };
    case FETCH_USERITEMS_ERROR:
        return {
            ...state,
            error: action.error,
            pending: false,
            fetched: false
        };
    case FETCH_USERITEMS_PENDING:
        return {
            ...state,
            pending: true,
            fetched: false
        }
    case REMOVE_USERITEMS:
        return {
            ...state,
            pending: false,
            fetched: false,    
            bookIds: [],
            libraryById: {},
            membership: {},
            expiredSubscriptions: [],
            address: {},
            id: '',
            error: null

        }
    case UPDATE_LIBRARY:
        return{
            ...state,
            libraryById: action.library,
            bookIds: action.bookIds,
        }
    default:
        return state;
    }
}
export const getUserItemsRedux = state => state.userItems;