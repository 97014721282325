import { GET_USER_SUCCESS,
    GET_USER_PENDİNG,
    GET_USER_ERROR,
    REMOVE_USER
} from './types';

export function fetchUserSuccess ( user ){
    return {
        type: GET_USER_SUCCESS,
        user: user
    }
}

export function fetchUserPending (){
    return {
        type: GET_USER_PENDİNG
    }
}

export function fetchUserError ( error ){
    return {
        type: GET_USER_ERROR,
        error: error
    }
}

export function removeUser (){
    return {
        type: REMOVE_USER
    }
}