import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
function useDropDownState( target ) {
    const [ dropDownState, setState ] = useState( false );
    const location = useLocation();

    useEffect( () => {
        function handleClick( event ) {
            if( event.target.parentElement === target.current|| event.target === target.current ){
                return
            } else {
                setState( false )
            }
        }
        if( dropDownState ){
            window.addEventListener( 'click', handleClick )
        }
        return ()=>{
            window.removeEventListener( 'click', handleClick )
        }
    }, [ dropDownState, target ] );
    useEffect( ()=>{
        return()=>{
            setState( false )
        }
    },[ location.pathname ] )
    function setDropDownState( param ) {
        setState( param )
    }
    return [ dropDownState,setDropDownState ];
}
export default useDropDownState;