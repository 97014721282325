import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import style from './banner-global.module.scss';
import Skeleton from '@mui/material/Skeleton';
import { staticUrl } from '../../api/endpoints';
const BannerGlobal = ()=>{
    const bannerData = useSelector( state=> state.pageFields.fields.banner )
    const isFetched = useSelector( state=> state.pageFields.fetched )
    const location = useLocation();
    if( !isFetched ){
        return <Skeleton variant="rect" animation="wave" classes={ { root: style.skeleton } } />
    }
    return (
        <div className={ style.container } >
            <div className={ style.midQuote }>
                {
                    location.pathname.split( '/' )[ 1 ] === 'dinle'?<>
                        seslerin dünyası
                    </>:location.pathname.split( '/' )[ 1 ] === 'izle'?<>
                        sınırsız video
                    </>:location.pathname.split( '/' )[ 1 ] === 'kitaplar'?<>
                        e-kitapların keyfini çıkar
                    </>:<>
                        kitap, video, podcast hepsi bir arada
                    </>
                }
            </div>
            <img src={ `${ staticUrl }${ bannerData.images[ 0 ] }` } className={ style.bannerGlobal } alt='banner girl on black' />
        </div>
    )
}
export default BannerGlobal