import { useMemo } from 'react';
import { normalize } from 'normalizr';

const useNormalize = ( data,model ) =>{
    const result = useMemo( ()=>{
        const res = normalize( data, model )
        return res
    },[ data, model ] )
    return result
}

export default useNormalize;