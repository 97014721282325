import React, { useEffect, useState } from 'react';
import style from './slider-reusable.module.scss';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar'

function SliderReusable ( props ){
    const [ audioDuration, setDuration ] = useState( '00:00' );
    const [ currentTime, setCurrent ] = useState( '00:00' );
    const [ timeOnHover, setTimeOnHover ] = useState( null );
    const [ bufferedTime, setBuffered ] = useState( 0 );

    useEffect( ()=>{
        // timer
        props.player.ontimeupdate = ()=>{
            const date = new Date( 0 );
            date.setSeconds( Math.floor( props.player.currentTime ) )
            Math.floor( props.player.currentTime ) >= 3600? setCurrent( date.toISOString().substr( 11, 8 ) ):setCurrent( date.toISOString().substr( 14, 5 ) );
        }
        props.player.onprogress = ()=> setBuffered( Math.floor( props.player.buffered.end( 0 ) )- Math.floor( props.player.currentTime ) )
        const date = new Date( 0 );
        date.setSeconds( Math.floor( props.player.duration ) )
        Math.floor( props.player.duration ) >= 3600? setDuration( date.toISOString().substr( 11, 8 ) ):setDuration( date.toISOString().substr( 14, 5 ) );
        return ()=>{
            setDuration( '00:00' )
            setBuffered( 0 );
            setCurrent( '00:00' );
            setTimeOnHover( null )
        }
    }, [ props.player ] )

    function handleSliderChange(){
        const val = document.getElementById( 'slider' ).value
        props.player.currentTime = val*props.player.duration/100;
    }
    function hoverProgress( e ){
        const left = e.target.getBoundingClientRect().left
        const leftSide = ( e.clientX - left )>0? ( e.clientX - left ): 0
        const date = new Date( 0 );
        date.setSeconds( Math.floor( ( leftSide / e.target.clientWidth )*props.player.duration ) )
        setTimeOnHover( date.toISOString().substr( 14, 5 ) )
    }

    return (
        <Row className={ style.slider_container }>
            <ReactTooltip id="hoverTime" type='light'
                className={ style.tool_tip }
            >
                {timeOnHover}
            </ReactTooltip>
            <input 
                data-tip
                data-for="hoverTime"
                type="range" 
                id='slider' 
                onInput={ handleSliderChange }
                min="0" max="100" 
                value={ ( props.player.currentTime/props.player.duration )? ( props.player.currentTime/props.player.duration )*100:0 } 
                step="0.1" 
                onMouseMove={ hoverProgress } 
                className={ style.slider }/>
            <ProgressBar className={ style.progress_alternate }>
                <ProgressBar className={ style.played_time } now={ ( props.player.currentTime/props.player.duration )? ( props.player.currentTime/props.player.duration )*100:0 } key={ 1 } />
                <ProgressBar className={ style.buffered_time } now={ bufferedTime/props.player.duration*100 } key={ 2 } />
            </ProgressBar>
            <Row className={ style.durations }>
                <Col className={ style.current_time } > {currentTime}</Col>
                <Col className={ style.duration_time }>{audioDuration}</Col>
            </Row>
        </Row>
    )
}
SliderReusable.propTypes = {
    player: PropTypes.object
}
export default SliderReusable;
