import { LIBRARY_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';
import { normalizeLibrary } from './normalize';
import {
    updateLibrary
} from '../../store/actions';

const updateTheLibrary = ( body, id ) => {
    return async( dispatch ) =>{
        try {
            const config = {
                method: 'PUT',
                url: `${ LIBRARY_URL }/${ id }`,
                responseType: 'json',
                data: body
            }
            const data = await internalAxios( config )
            if( data.data.success ){
                const normalized = await normalizeLibrary( data.data.data );
                dispatch( updateLibrary( {
                    library: normalized.entities.library,
                    bookIds: normalized.result
                } ) )
            }
            return null
        } catch ( err ) {
            return null
        }
    }
}
export default updateTheLibrary;