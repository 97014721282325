import { schema, normalize } from 'normalizr';

const ganreSchema = new schema.Entity( 'ganres',{}, { idAttribute: '_id' } )

const groupIdsByClass = ( ganres )=>{
    const result = {};
    ganres.forEach( element => {
        if( !result[ element.class.toLowerCase() ] ){
            result[ element.class.toLowerCase() ] = []
        } 
        result[ element.class.toLowerCase() ].push( element._id )
    } );
    return result
}

export const normalizeGanresStore = async( ganres )=>{
    const normalized = await normalize( ganres, [ ganreSchema ] )
    const idsByClass = await groupIdsByClass( ganres )
    const res = { ...{        
        ganres: normalized.entities.ganres,
        allIds: [ ...new Set( normalized.result ) ] }, ...idsByClass
    }
    return res;
}