import { PAGE_FIELDS_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';
import {
    fetchPageFieldsError,
    fetchPageFieldsPending,
    fetchPageFieldsSuccess
} from '../../store/actions/page-fields-actions';

const getPageFields =( queryData ) => {
    return async( dispatch )=>{
        dispatch( fetchPageFieldsPending() )
        const query = queryData? queryData: {};
        const config = {
            method: 'GET',
            url: `${ PAGE_FIELDS_URL }`,
            params: query,
            responseType: 'json'
        }
        try {
            const data = await internalAxios( config )
            if( data.data.success ){
                dispatch( fetchPageFieldsSuccess( data.data.data[ 0 ] ) )
                return data.data.data[ 0 ]
            }
            dispatch( fetchPageFieldsError( 'was not able to fetch' ) )
            return null
        } catch ( err ) {
            dispatch( fetchPageFieldsError( err ) )
            return err
        }
    }
}
export default getPageFields;
