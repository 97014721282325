import React from 'react';
import style from './footer.module.scss';
import { useNavigate } from 'react-router-dom';
import Linkedin from '../../assets/icons/Linkedin/LinkedIn.png';
import Instagram from '../../assets/icons/instagram.png';
import Facebook from '../../assets/icons/Facebook/Facebook.png';
import Twitter from '../../assets/icons/Twitter/Twitter.png';
import logoDark from '../../assets/logos/logo-dark.png';
import logoLight from '../../assets/logos/papi-kitap-logo-white.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

function Footer( { color } ) {
    const navigate = useNavigate();

    function redirectInternal( path ){
        navigate( path )
    }
    function redirectExternal( path ){
        window.open( path )
    }
    return (
        <>
            <Row as="div" className={ color === 'dark'? style.wrapper_dark_background: style.wrapper_light_background }>
                <Row as="div" className={ style.content }>
                    <Col className={ style.link_group }>
                        <Col as="div" className={ style.link_header }>
                            Kitaplar
                        </Col>
                        <Col className={ style.link }>
                            Bu ayın kitapları
                        </Col>
                        <Col className={ style.link }>
                            En çok okunanlar
                        </Col>
                        <Col className={ style.link }>
                            Yeni çıkanlar
                        </Col>
                        <Col className={ style.link }
                            onClick={ ()=>redirectInternal( '/tum-kitaplar' ) }>
                            Kategoriler
                        </Col>
                    </Col>
                    <Col className={ style.link_group }>
                        <Col as="div" className={ style.link_header }>
                            Videolar
                        </Col>
                        <Col className={ style.link }>
                            En çok izlenenler
                        </Col>
                        <Col className={ style.link }>
                            Diziler
                        </Col>
                        <Col className={ style.link }>
                            Yeni çıkanlar
                        </Col>
                        <Col className={ style.link }
                            onClick={ ()=>redirectInternal( '/' ) }>
                            Kategoriler
                        </Col>
                    </Col>
                    <Col className={ style.link_group }>
                        <Col as="div" className={ style.link_header }>
                            Podcastler
                        </Col>
                        <Col className={ style.link }>
                            En çok dinlenenler
                        </Col>
                        <Col className={ style.link }>
                            Yeni çıkanlar
                        </Col>
                        <Col className={ style.link }
                            onClick={ ()=>redirectInternal( '/' ) }>
                            Kategoriler
                        </Col>
                    </Col>
                    <Col className={ style.link_group }>
                        <Col as="div" className={ style.link_header }>
                            Hakkımızda
                        </Col>
                        <Col className={ style.link }>
                            Hakkımızda
                        </Col>
                        <Col className={ style.link }>
                            Sık sorulan sorular
                        </Col>
                        <Col className={ style.link }>
                            Kariyer
                        </Col>
                        <Col className={ style.link }
                            onClick={ ()=>redirectInternal( '/giris' ) }>
                            Üyelik
                        </Col>
                    </Col>
                    <Col className={ style.social_media_col }>
                        <Row className={ style.social_media_header }>
                            Hikayemize gözatın
                        </Row>
                        <Row className={ style.social_media_icon }>
                            <img alt=""
                                src={ Linkedin }
                                onClick={ ()=> redirectExternal( 'https://www.linkedin.com' ) }>
                            </img>
                            <img 
                                alt=""
                                src={ Instagram }
                                onClick={ ()=> redirectExternal( 'https://www.instagram.com' ) }>
                            </img>
                        </Row>
                        <Row className={ style.social_media_icon }>
                            <img alt=""
                                src={ Facebook }
                                onClick={ ()=> redirectExternal( 'https://www.facebook.com' ) }>
                            </img>
                            <img 
                                alt=""
                                src={ Twitter }
                                onClick={ ()=> redirectExternal( 'https://www.twitter.com' ) }>
                            </img>
                        </Row>
                    </Col>
                </Row>
                <Row as="div" className={ style.info }>
                    <Col className={ style.logo }>
                        <img src={ color === 'dark'? logoLight: logoDark }
                            alt="logo-papi"
                            onClick={ ()=>redirectInternal( '/' ) }>
                        </img>
                    </Col>
                    <Col className={ style.info_content }>
                        <Row className={ style.privacy_aggrement }>
                            <p className="agreement">Kullanıcı Sözleşmesi</p>
                            <p className="privicy">Gizlilik</p>
                        </Row>
                        <Row className={ style.trademark }>
                            ©  2021. PapiKitap Medya, Yatıncılık  A.Ş. Papkitap, Papikitap-blog, Papikitap Külübü, Papikitap- Yaz  Papikitap A.Ş  patentli markalarıdır.
                        </Row>
                    </Col>
                </Row>
            </Row>
        </>
    );
}
Footer.propTypes = {
    color: PropTypes.string
}
const withBackgroundColor = ( Component, color )=>{
    function FooterWithColor ( props ){
        return(
            <Component { ...props } color={ color } />
        );
    }
    return FooterWithColor
}
const DarkFooter = withBackgroundColor( Footer, 'dark' );
const LightFooter = withBackgroundColor( Footer, 'light' );
export { DarkFooter, LightFooter };
