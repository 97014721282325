const url = process.env.NODE_ENV === 'production'? 'https://papi-backend.herokuapp.com/api/rest/': 'http://localhost:8080/api/rest/';
const BOOKS_URL = '/books';
const LIBRARY_URL = '/userlibraries';
const CART_URL = '/usercart';
const REGISTER_URL = '/auth/register';
const USER_ITEMS_URL = '/useritems';
const GANRES_URL = '/ganres';
const PAGE_FIELDS_URL = '/pagefields';
const EDITORIALS_URL = '/editorials';
const COMMENTS_URL = '/comments';
const AUTHORS_URL = '/authors';
const DOCS_URL = '/docs';
const VIDEOS_URL = '/videos';
const AUDIOS_URL = '/audios';
const SERIES_URL = '/series';
// asset url
const assetsUrl = 'https://assets.papikitap.com/'
const staticUrl = 'https://static.papikitap.com/'

export { url, BOOKS_URL,
    LIBRARY_URL, CART_URL,
    REGISTER_URL, 
    USER_ITEMS_URL, 
    GANRES_URL,
    PAGE_FIELDS_URL,
    assetsUrl,
    EDITORIALS_URL,
    COMMENTS_URL,
    AUTHORS_URL,
    DOCS_URL,
    VIDEOS_URL,
    AUDIOS_URL,
    staticUrl,
    SERIES_URL
}