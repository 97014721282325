import {
    FETCH_SERIES_SUCCESS,
    FETCH_SERIES_ERROR,
    FETCH_SERIES_PENDING
} from './types';

export function fetchSeriesSuccess( data ) {
    return {
        type: FETCH_SERIES_SUCCESS,
        byId: data.byId,
        allIds: data.allIds
    }
}

export function fetchSeriesError( error ) {
    return {
        type: FETCH_SERIES_ERROR,
        error: error
    }
}
export function fetchSeriesPending() {
    return {
        type: FETCH_SERIES_PENDING
    }
}
