import {
    FETCH_VIDEOS_ERROR,
    FETCH_VIDEOS_PENDING,
    FETCH_VIDEOS_SUCCESS
} from './types';

export function fetchVideosSuccess( data ) {
    return {
        type: FETCH_VIDEOS_SUCCESS,
        byId: data.byId,
        allIds: data.allIds,
        episodic: data.episodic,
        nonEpisodic: data.nonEpisodic,
        featured: data.featured? data.featured:[]
    }
}

export function fetchVideosError( error ) {
    return {
        type: FETCH_VIDEOS_ERROR,
        error: error
    }
}
export function fetchVideosPending() {
    return {
        type: FETCH_VIDEOS_PENDING
    }
}