import { BOOKS_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';
import {
    fetchBooksError,
    fetchBooksSuccess, 
    fetchBooksPending,
    fetchAuthorsSuccess,
    updateManyGanre,
    fetchPublishersSuccess, fetchSeriesSuccess
} from '../../store/actions';
import { normalizeForBookStore, groupIdsByGanreAuthorAndSerie } from './normalize-and-functions';

const getAllBooksStore = ( queryData ) => {
    return async( dispatch )=>{
        try {
            dispatch( fetchBooksPending() )
            const query = queryData? queryData: {
                data: { completed: true }
            };
            const config = {
                method: 'GET',
                url: `${ BOOKS_URL }`,
                params: query,
                responseType: 'json'
            }
            const data = await internalAxios( config )
            if( data.data.success ){
                const resSorted = await groupIdsByGanreAuthorAndSerie( data.data.data );
                const res = await normalizeForBookStore( data.data.data )
                await dispatch( fetchAuthorsSuccess( {
                    allIds: Object.keys( resSorted.byAuthor ),
                    byId: resSorted.byAuthor
                } ) )
                await dispatch( updateManyGanre( resSorted.byGanre ) )
                await dispatch( fetchPublishersSuccess( {
                    allIds: Object.keys( res.entities.publishers ),
                    byId: res.entities.publishers
                } ) )
                await dispatch( fetchSeriesSuccess( {
                    allIds: Object.keys( resSorted.bySerie ),
                    byId: resSorted.bySerie
                } ) )
                await dispatch( fetchBooksSuccess( { allIds: res.result,
                    byId: res.entities.books } ) )
                return res.result;
            }
            return null
        } catch ( err ) {
            dispatch( fetchBooksError( err ) )
            return null
        }
    }
}
export default getAllBooksStore;