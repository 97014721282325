import {
    GET_USER_ERROR,
    GET_USER_PENDİNG,
    GET_USER_SUCCESS,
    REMOVE_USER
} from '../actions/types';

const initialState = {
    user: {},
    pending: false,
    isFetched: false,
    error: null
}

export default function ( state = initialState, action ){
    switch ( action.type ) {
    case GET_USER_SUCCESS:
        return {
            ...state,
            user: action.user,
            isFetched: true,
            pending: false,
            error: null
        }
    case GET_USER_PENDİNG:
        return{
            ...state,
            user:{},
            isFetched: false,
            pending: true,
            error: null
        }
    case GET_USER_ERROR:
        return {
            ...state,
            user: {},
            isFetched: false,
            pending: false,
            error: action.error
        }
    case REMOVE_USER:
        return{
            ...state,
            user:{},
            isFetched: false,
            pending: false,
            error: null
        }
    default:
        return state; 
    }
}
export const getUserRedux = state => state.user;