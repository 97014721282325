import { USER_ITEMS_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';
import {
    fetchUserItemsError,
    fetchUserItemsPending,
    fetchUserItemsSuccess
} from '../../store/actions';
import { normalizeUserItems } from './normalize';

const updateUserItems = ( body, id )=>{
    return async( dispatch )=>{
        dispatch( fetchUserItemsPending() )
        const config = {
            method: 'PUT',
            url: `${ USER_ITEMS_URL }/${ id }`,
            responseType: 'json',
            data: body
        }
        try {
            const data = await internalAxios( config )
            if( data.data.success ){
                const normalizedLibrary = await normalizeUserItems( data.data.data )
                const result = {
                    libraryById: normalizedLibrary.result? normalizedLibrary.entities.library: {},
                    bookIds: normalizedLibrary.result? normalizedLibrary.entities.userItems[ normalizedLibrary.result ].library: [],
                    expiredSubscriptions: normalizedLibrary.result? normalizedLibrary.entities.userItems[ normalizedLibrary.result ].expiredSubscriptions: [],
                    membership: normalizedLibrary.result? normalizedLibrary.entities.userItems[ normalizedLibrary.result ].membership: {},
                    address: normalizedLibrary.result? normalizedLibrary.entities.userItems[ normalizedLibrary.result ].address: {},
                    id: normalizedLibrary.result? normalizedLibrary.result: ''
                }
                dispatch( fetchUserItemsSuccess( result ) )
                return result
            }
            return null
        } catch ( err ) {
            dispatch( fetchUserItemsError( err ) )
            return null
        }
    }
}
export default updateUserItems;