import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import style from './style.module.scss';
function PageFullBooksLoading (){
    return (
        <div className={ style.container_page_full }>
            <Skeleton variant="rect" animation="wave" className={ style.rect }/>
            <Skeleton variant="rect" animation="wave" className={ style.rect }/>
            <Skeleton variant="rect" animation="wave" className={ style.rect }/>
            <Skeleton variant="rect" animation="wave" className={ style.rect }/>
            <Skeleton variant="text" animation="wave" className={ style.text } />
            <Skeleton variant="text" animation="wave" className={ style.text }/>
            <Skeleton variant="text" animation="wave" className={ style.text }/>
            <Skeleton variant="text" animation="wave" className={ style.text }/>
            <Skeleton variant="text" animation="wave" className={ style.text } />
            <Skeleton variant="text" animation="wave" className={ style.text }/>
            <Skeleton variant="text" animation="wave" className={ style.text }/>
            <Skeleton variant="text" animation="wave" className={ style.text }/>
        </div>
    )
}
export default PageFullBooksLoading;