import { FETCH_BOOKS_ERROR, 
    FETCH_BOOKS_SUCCESS,
    FETCH_BOOKS_PENDING
} from './types';

export function fetchBooksSuccess( data ) {
    return {
        type: FETCH_BOOKS_SUCCESS,
        byId: data.byId,
        allIds: data.allIds
    }
}

export function fetchBooksError( error ) {
    return {
        type: FETCH_BOOKS_ERROR,
        error: error
    }
}
export function fetchBooksPending() {
    return {
        type: FETCH_BOOKS_PENDING
    }
}
