import { SERIES_URL } from '../endpoints';
import internalAxios from '../utilities/axios-instances';
import {
    fetchSeriesSuccess,
    fetchSeriesPending,
    fetchSeriesError
} from '../../store/actions';
import { normalizeSeries } from './normalize';

const getAllSeriesStore = ( queryData ) => {
    return async( dispatch )=>{
        try {
            dispatch( fetchSeriesPending() )
            const query = queryData? queryData: {
            };
            const config = {
                method: 'GET',
                url: `${ SERIES_URL }`,
                params: query,
                responseType: 'json'
            }
            const data = await internalAxios( config )
            if( data.data.success ){
                const finalData = normalizeSeries( data.data.data )
                dispatch( fetchSeriesSuccess( {
                    byId: finalData.entities.series,
                    allIds: finalData.result } ) )
                return true
            }
            dispatch( fetchSeriesError( 'no-data' ) )
            return null
        } catch ( err ) {
            dispatch( fetchSeriesError( err ) )
            return null
        }
    }
}
export default getAllSeriesStore;