import { 
    FETCH_EDITORIALS_PENDING,
    FETCH_EDITORIALS_SUCCESS,
    FETCH_EDITORIALS_ERROR
} from '../actions/types';

const initialState = {
    byItemId: {},
    allIds: [],
    error: null,
    pending: false
}
export default function( state = initialState, action ) {

    switch ( action.type ) {

    case FETCH_EDITORIALS_SUCCESS:
        return {
            ...state,
            byItemId: { ...state.byItemId,...action.byItemId }, 
            allIds: [ ...new Set( [ ...state.allIds,...action.allIds ] ) ],
            pending: false
        };
    case FETCH_EDITORIALS_ERROR:
        return {
            ...state,
            error: action.error,
            pending: false
        };
    case FETCH_EDITORIALS_PENDING:
        return {
            ...state,
            pending: true
        }
    default:
        return state;
    }
}