import { GANRES_URL } from '../../endpoints';
import internalAxios from '../../utilities/axios-instances';
import {
    fetchAllGanresError,
    fetchAllGanresSuccess,
    fetchAllGanresPending
} from '../../../store/actions/ganre-actions';
import {
    normalizeGanresStore
} from '../normalize';

const getAGanreAndStore = ( id ) => {

    return async( dispatch )=>{
        const config = {
            method: 'GET',
            url: `${ GANRES_URL }/${ id }`,
            responseType: 'json'
        }
        try {
            dispatch( fetchAllGanresPending )
            const data = await internalAxios( config )
            if( data.data.success ){
                const finalData = await normalizeGanresStore( [ data.data.data ] )
                dispatch( fetchAllGanresSuccess( finalData ) )
                return true
            }
            return null
        } catch ( err ) {
            dispatch( fetchAllGanresError( err ) )
            return null
        }
    }
}
export default getAGanreAndStore;