import { SET_ISLOADING_TRUE, SET_ISLOADING_FALSE, SET_ISLOADING_ERROR } from '../actions/types';

const initialState = true

export default function( state = initialState, action ) {
    switch ( action.type ) {
  
    case SET_ISLOADING_TRUE:
        return true
    case SET_ISLOADING_FALSE:
        return false
    case SET_ISLOADING_ERROR:
        return action.error
    default:
        return state;
    }
}

export const getIsloading = state=> state