import { schema, normalize } from 'normalizr';

const librarySchema = new schema.Entity( 'library',{}, { idAttribute: 'book' } )

const itemsSchema = new schema.Entity( 'userItems', {
    library: [
        librarySchema
    ]
}, { idAttribute: '_id' } )

export const normalizeUserItems = ( userItems )=>{
    const res = normalize( userItems, itemsSchema )
    return res;
}