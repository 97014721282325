import firebase from 'firebase/app';

function toSignOut( path, redirect ) {

    firebase.auth().signOut().then( () => {
        redirect( `${ path }` );
    } ).catch( ( error ) => {
        return error;
    } );
}

export default toSignOut