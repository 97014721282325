import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAndStoreAllBooks } from '../../../api'
import { fetchBooksPending } from '../../../store/actions';
import PropTypes from 'prop-types';

function DataFetcher ( { data, children } ){
    const dispatch = useDispatch()
    const books = useSelector( state=> state.books.byId );
    useEffect( ()=>{
        dispatch( fetchBooksPending() )
        const ids = []
        if( Array.isArray( data ) ){
            data.forEach( e=>{
                if( !books[ e ] ){
                    ids.push( e )
                }
            } )
        } else {
            Object.keys( data ).forEach( e=>{
                if( !books[ data[ e ].id ] ){
                    ids.push( data[ e ].id )
                }
            } )
        }
        if( ids.length > 0 ){
            dispatch( getAndStoreAllBooks( {
                data: {
                    '_id':{
                        '$in': ids
                    },
                    'completed': true
                }
            } ) )
        }
    }, [ books, data, dispatch ] )
    return children;
}
DataFetcher.propTypes = {
    data: PropTypes.oneOfType( [
        PropTypes.array,
        PropTypes.object
    ] ),
    children: PropTypes.object

}
export default DataFetcher;