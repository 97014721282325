import React, { useEffect, useState, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

function DropDown( { style, children, title, arrow } ){
    const [ drShow, setShow ] = useState( false )
    const menuRef = useRef( null );
    useEffect( ()=>{
        if( drShow ){
            window.addEventListener( 'click', handleClick )
        }
        return ()=>{
            window.removeEventListener( 'click', handleClick )
        }
    }, [ drShow ] )
    function handleClick( event ) {
        if( event.target.parentElement === menuRef.current || event.target === menuRef.current ){
            return
        } else {
            setShow( false )
        }
    }
    return (
        <> 
            <div className={ style.dropdownButton } onClick={ ()=> setShow( !drShow ) }>
                <span className={ style.text }>{title}</span>
                {arrow&&<ArrowDropDownIcon />}
            </div>
            {drShow&&
                <div className={ style.dropdownMenu } ref={ menuRef }>
                    {children}
                </div>
            }
        </>
    )
}
DropDown.propTypes={
    style: PropTypes.object,
    title: PropTypes.string,
    children: PropTypes.oneOfType( [
        PropTypes.array,
        PropTypes.object
    ] ),
    arrow: PropTypes.bool
}
DropDown.defaultProps = {
    arrow:true
}
export default DropDown;