import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import style from './carousel-reusable.module.scss';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function CarouselReusable( props ){
    const slider = useRef( null );

    return(
        <div className={ style.container }>
            <div className={ style.carousel_wrapper }>
                <Slider { ...{ ...props.settings,...{ arrows:false } } } ref={ slider } >
                    {props.children}
                </Slider>
            </div>
            {props.arrowReq&&
            <div className={ props.style.arrows? props.style.arrows:style.arrows }>
                <NavigateBeforeIcon classes={ { root: props.style.arrowButton? props.style.arrowButton: style.arrow_button } } onClick={ ()=> slider.current.slickPrev() } />
                <NavigateNextIcon classes={ { root: props.style.arrowButton? props.style.arrowButton: style.arrow_button } } onClick={ ()=> slider.current.slickNext() } />
            </div>
            }
        </div>
    )
}
CarouselReusable.propTypes = {
    children: PropTypes.oneOfType( [
        PropTypes.array,
        PropTypes.object
    ] ),
    settings: PropTypes.object,
    style: PropTypes.object,
    arrowReq: PropTypes.bool
}
CarouselReusable.defaultProps = {
    style: {},
    arrowReq: true
}
export default CarouselReusable;