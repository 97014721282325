import React from 'react';
import PropTypes from 'prop-types';

function Image( { style, source, alt } ){
    return (
        <>
            <img src={ `https://assets.papikitap.com/${ source }` } className={ style } alt={ alt } />
        </>
    )
}
Image.propTypes = {
    source: PropTypes.string,
    style: PropTypes.string,
    alt: PropTypes.string
}
export default Image