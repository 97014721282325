import React, { lazy, useEffect, Suspense } from 'react';
import style from './main-router.module.scss';
import { Routes, Route } from 'react-router-dom';
import { ScrollToTop, RouterSuspense } from './utilities';
import Logo from '../assets/logos/papi.png';
import { useDispatch, useSelector } from 'react-redux';
import { MainLayout, FullLayout, BooksLayout, 
    VideosLayout, AudioLayout } from './layouts/layouts';
import { getUsertItemsStore } from '../api';
import Skeleton from '@mui/material/Skeleton';

const Home = lazy( () => import( '../components/home/home' ) );
const DefaultPage = lazy( () => import( '../components/default/default-page' ) );
// auth
const User = lazy( () => import( '../components/user/account-main' ) );
const Register = lazy( ()=> import( '../components/auth/register/register' ) );
const MemberShipPacks = lazy( ()=> import( '../components/auth/membership-packs/component' ) );
const UserLibrary = lazy( () => import( '../components/user-library/user-library' ) );
// audio
const AudioMain = lazy( ()=> import( '../components/audios/audios-main-page/audios-main-page' ) );
const SingleAudio = lazy( ()=> import( '../components/audios/single-audio/single-audio' ) );
const AudioContent = lazy( ()=> import( '../components/audios/audio-content/audio-content' ) );
const AudiosByGanres = lazy( ()=> import( '../components/audios/by-ganres/audios-by-ganre' ) );
const ListeningList = lazy( ()=> import( '../components/audios/listening-list/listening-list' ) );
// video
const VideoByClass = lazy( ()=> import( '../components/videos/video-by-class/video-by-class' ) );
const VideoMain = lazy( () => import( '../components/videos/video-main/video-main' ) );
const VideoByGanres = lazy( () => import( '../components/videos/video-by/video-by-ganres' ) );
const VideoHome = lazy( () => import( '../components/videos/video-home/video-home' ) );
// book
const AllBooks = lazy( () => import( '../components/books/all-books/all-books' ) );
const Book = lazy( () => import( '../components/books/book/book' ) );
const BookByGanres = lazy( () => import( '../components/books/books-by/books-by-ganres/ganre' ) );
const BooksByAuthor = lazy( ()=> import( '../components/books/books-by/books-by-author/author' ) );
const BooksMain = lazy( ()=> import( '../components/books/books-main/books-main' ) );
// epub
const EpubWrap = lazy( ()=> import( '../components/epub-reader/reader-wrap' ) );
const ReaderTest = lazy( ()=> import( '../components/epub-reader/reader-test' ) );

function MainRouter() {
    const { user, ganres } = useSelector( state=> state );
    const dispatch = useDispatch();
    useEffect( ()=>{
        if( user.isFetched && user.user.email ){
            dispatch( getUsertItemsStore( user.user.email ) )
        }
    }, [ user.isFetched, user.user.email, dispatch ] )
    if( user.pending || !ganres.fetched ){
        return <div className={ style.loading }>
            <Skeleton variant="rect" animation='pulse' className={ style.skeleton } />
            <img className={ style.logo } src={ Logo } />
        </div>
    }
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route element={ <MainLayout/> }>
                    <Route path="/" element={
                        <Suspense fallback={ <RouterSuspense/> }>
                            <Home />
                        </Suspense> }/>

                    {/* auth */}
                    <Route path="uyelik/*" element={ <Suspense fallback={ <RouterSuspense/> }><MemberShipPacks /></Suspense> }/>
                    <Route path='giris-yap/*' element={ <Suspense fallback={ <RouterSuspense/> }><Register/></Suspense> }/>
                    <Route path='kutuphane/*' element={ <Suspense fallback={ <RouterSuspense/> }><UserLibrary/></Suspense> }/>
                    <Route path='hesabim/*' element={ <Suspense fallback={ <RouterSuspense/> }><User /></Suspense> }/>

                    {/* default */}
                    <Route path="*" element={ <Suspense fallback={ <RouterSuspense/> }>
                        <DefaultPage /></Suspense> }/>
                </Route>
                <Route element={ <FullLayout/> }>
                    {/* Reader */}
                    <Route path="reader/:bookId/*" element={ <Suspense fallback={ <RouterSuspense/> }><EpubWrap /></Suspense> }/>
                    <Route path='reader-test/:bookId/*' element={ <Suspense fallback={ <RouterSuspense/> }><ReaderTest/></Suspense> }/>
                </Route>
                {/* video */}
                <Route element={ <VideosLayout/> }>
                    <Route path="izle" element={ <Suspense fallback={ <RouterSuspense/> }><VideoMain /></Suspense> }>
                        <Route index element={ <Suspense fallback={ <RouterSuspense/> }><VideoHome /></Suspense> }/>   
                        <Route path="*"element={ <Suspense fallback={ <RouterSuspense/> }><VideoHome /></Suspense> }/>                               
                        <Route path='c/:classId/*' element={ <Suspense fallback={ <RouterSuspense/> }><VideoByClass /></Suspense> }/>
                        <Route path='t/:ganreId/*' element={ <Suspense fallback={ <RouterSuspense/> }><VideoByGanres /></Suspense> }/>
                        <Route path='izlediklerim/*' element={ <Suspense fallback={ <RouterSuspense/> }><VideoByGanres /></Suspense> }/>
                    </Route>
                </Route>
                <Route element={ <AudioLayout/> }>
                    {/* audio */}
                    <Route path="dinle" element={ <Suspense fallback={ <RouterSuspense/> }><AudioMain /></Suspense> }>
                        <Route index element={ <Suspense fallback={ <RouterSuspense/> }><AudioContent /></Suspense> }/>
                        <Route path='*' element={ <Suspense fallback={ <RouterSuspense/> }><AudioContent /></Suspense> }/>
                        <Route path='t/:ganreId/*' element={ <Suspense fallback={ <RouterSuspense/> }><AudiosByGanres /></Suspense> }/>
                        <Route path='p/:audioId/*' element={ <Suspense fallback={ <RouterSuspense/> }><SingleAudio /></Suspense> }/>
                        <Route path='dinlediklerim/*' element={ <Suspense fallback={ <RouterSuspense/> }><ListeningList /></Suspense> }/>
                    </Route>
                </Route>
                <Route element={ <BooksLayout/> }>
                    {/* book */}
                    <Route path='kitaplar' element={ <Suspense fallback={ <RouterSuspense/> }><BooksMain /></Suspense> }>
                        <Route index element={ <Suspense fallback={ <RouterSuspense/> }><AllBooks /></Suspense> }/>
                        <Route path="*"element={ <Suspense fallback={ <RouterSuspense/> }><AllBooks /></Suspense> }/>
                        <Route path='k/:bookId/*' element={ <Suspense fallback={ <RouterSuspense/> }><Book/></Suspense> }/>
                        <Route path='t/:ganreId/*' element={ <Suspense fallback={ <RouterSuspense/> }><BookByGanres/></Suspense> }/>
                        <Route path='y/:authorId/*' element={ <Suspense fallback={ <RouterSuspense/> }><BooksByAuthor /></Suspense> }/>
                        <Route path='okuduklarim/*' element={ <Suspense fallback={ <RouterSuspense/> }><AllBooks /></Suspense> }/>
                        <Route path='yazarlar/*' element={ <Suspense fallback={ <RouterSuspense/> }><AllBooks /></Suspense> }/>
                    </Route>
                </Route>
            </Routes>
        </>
    );

}
export default MainRouter;
