import { GANRES_URL } from '../../endpoints';
import internalAxios from '../../utilities/axios-instances';
import {
    fetchAllGanresError,
    fetchAllGanresSuccess,
    fetchAllGanresPending,
    removeGanres
} from '../../../store/actions/ganre-actions';
import {
    normalizeGanresStore
} from '../normalize';

const getAllGanresForStore = () => {

    return async( dispatch )=>{
        const config = {
            method: 'GET',
            url: `${ GANRES_URL }`,
            responseType: 'json'
        }
        try {
            dispatch( fetchAllGanresPending )
            const data = await internalAxios( config )
            if( data.data.success ){
                const finalData = await normalizeGanresStore( data.data.data )
                dispatch( fetchAllGanresSuccess( finalData ) )
                return finalData
            }
            dispatch( removeGanres( ) )
            return null
        } catch ( err ) {
            dispatch( fetchAllGanresError( err ) )
            return err
        }
    }
}
export default getAllGanresForStore;