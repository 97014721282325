export const FETCH_BOOKS_SUCCESS = 'FETCH_BOOKS_SUCCESS';
export const FETCH_BOOKS_ERROR = 'FETCH_BOOKS_ERROR';
export const FETCH_BOOKS_PENDING = 'FETCH_BOOKS_PENDING';

export const FETCH_VIDEOS_SUCCESS = 'FETCH_VIDEOS_SUCCESS';
export const FETCH_VIDEOS_ERROR = 'FETCH_VIDEOS_ERROR';
export const FETCH_VIDEOS_PENDING = 'FETCH_VIDEOS_PENDING';

export const SET_ISLOADING_TRUE = 'SET_ISLOADING_TRUE';
export const SET_ISLOADING_FALSE = 'SET_ISLOADING_FALSE';
export const SET_ISLOADING_ERROR = 'SET_ISLOADING_ERROR';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_PENDİNG = 'GET_USER_PENDİNG';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const REMOVE_USER = 'REMOVE_USER';

export const FETCH_ALL_GANRES_SUCCESS = 'FETCH_ALL_GANRES_SUCCESS';
export const FETCH_ALL_GANRES_ERROR = 'FETCH_ALL_GANRES_ERROR';
export const FETCH_ALL_GANRES_PENDING = 'FETCH_ALL_GANRES_PENDING';
export const UPDATE_ONE_GANRE = 'UPDATE_ONE_GANRE';
export const REMOVE_ALL_GANRES = 'REMOVE_ALL_GANRES';
export const UPDATE_MANY_GANRES = 'UPDATE_MANY_GANRES';

export const FETCH_PAGEFIELDS_SUCCESS = 'FETCH_PAGEFIELDS_SUCCESS';
export const FETCH_PAGEFIELDS_ERROR = 'FETCH_PAGEFIELDS_ERROR';
export const FETCH_PAGEFIELDS_PENDING = 'FETCH_PAGEFIELDS_PENDING';
// authors
export const FETCH_AUTHORS_PENDING = 'FETCH_AUTHORS_PENDING';
export const FETCH_AUTHORS_ERROR = 'FETCH_AUTHORS_ERROR';
export const FETCH_AUTHORS_SUCCESS = 'FETCH_AUTHORS_SUCCESS';

export const FETCH_PUBLISHERS_PENDING = 'FETCH_PUBLISHERS_PENDING';
export const FETCH_PUBLISHERS_ERROR = 'FETCH_PUBLISHERS_ERROR';
export const FETCH_PUBLISHERS_SUCCESS = 'FETCH_PUBLISHERS_SUCCESS';

// editorial
export const FETCH_EDITORIALS_PENDING = 'FETCH_EDITORIALS_PENDING';
export const FETCH_EDITORIALS_SUCCESS = 'FETCH_EDITORIALS_SUCCESS';
export const FETCH_EDITORIALS_ERROR = 'FETCH_EDITORIALS_ERROR';

// userlibrary
export const FETCH_USERITEMS_PENDING = 'FETCH_USERITEMS_PENDING';
export const FETCH_USERITEMS_SUCCESS = 'FETCH_USERITEMS_SUCCESS';
export const FETCH_USERITEMS_ERROR = 'FETCH_USERITEMS_ERROR';
export const UPDATE_LIBRARY = 'UPDATE_LIBRARY';
export const REMOVE_USERITEMS = 'REMOVE_USERITEMS';

// audios

export const FETCH_ALL_AUDIOS_PENDING = 'FETCH_ALL_AUDIOS_PENDING';
export const FETCH_ALL_AUDIOS_SUCCESS = 'FETCH_ALL_AUDIOS_SUCCESS';
export const FETCH_ALL_AUDIOS_ERROR = 'FETCH_ALL_AUDIOS_ERROR';
export const SET_LISTENING = 'SET_LISTENING';

export const FETCH_SERIES_SUCCESS = 'FETCH_SERIES_SUCCESS';
export const FETCH_SERIES_ERROR = 'FETCH_SERIES_ERROR';
export const FETCH_SERIES_PENDING = 'FETCH_SERIES_PENDING';