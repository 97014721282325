import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './add-to-library.module.scss';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import {
    addToLibrary
} from '../../../api'

const AddtoLibOrDown = ( { book, style, addButton, downloadButton, children } ) =>{
    const { user, isFetched } = useSelector( state=> state.user );
    const { userItems } = useSelector( state=> state );
    const [ waiting, setWaiting ] = useState( false )
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function addLibrary( e ){
        e.preventDefault()
        setWaiting( true )
        try {
            if( !isFetched ){
                navigate( '/giris-yap' )
            } else {
                if( userItems.membership.dateExpiration 
                    && new Date( userItems.membership.dateExpiration ).getTime() > new Date().getTime() ){
                    await dispatch( addToLibrary( user.email, {
                        book: book._id,
                        dateAdded: `${ new Date() }`
                    } ) )
                } else {
                    navigate( '/uyelik' )
                }
            }
            setWaiting( false )
        } catch ( error ) {
            setWaiting( false )
            return error
        }
    }
    function downLoadTheBook(){
        if( isFetched ){
            return user
        }
    }
    return(
        <>
            {addButton&&
                <> 
                    {
                        waiting? 
                            <><Button className={ style.addToButton? style.addToButton:styles.addToButton } variant="light"
                            ><Spinner animation="border"/></Button>
                            </>
                            :
                            <>
                                {
                                    userItems.bookIds && userItems.bookIds.includes( book._id )?
                                        <>
                                            <Button className={ style.addToButton? style.addToButton:styles.addToButton } 
                                                variant="success"
                                                disabled
                                            ><CheckIcon />Kütüphanende</Button>
                                        </>
                                        :
                                        <>
                                            <Button className={ style.addToButton? style.addToButton:styles.addToButton } variant="light"
                                                onClick={ ( e )=> addLibrary( e ) }>kütüphaneme ekle</Button>
                                        </>
                                }
                            </>

                    }
                    {
                    }
                </>
            }
            { downloadButton&&<> <Button 
                className={ style.downloadButton? style.downloadButton: styles.downloadButton }
                onClick={ downLoadTheBook }
                variant="light">Okumaya başla</Button>{''}</>}
            {
                children&& <>
                    {children}
                </>
            }
        </>
    )
}
AddtoLibOrDown.propTypes = {
    style: PropTypes.object,
    book: PropTypes.object,
    addButton:PropTypes.bool,
    downloadButton: PropTypes.bool,
    children: PropTypes.object
}
AddtoLibOrDown.defaultProps={
    style: {}
}
export default AddtoLibOrDown;