import React from 'react';
import style from './App.module.scss';
import MainRouter from './router-nav-layouts/main-router';
import init from './initation/init';
import {
    firebaseObserve,
    Firebase,
    FirebaseContext
} from './initation/firebase-init';
import { BrowserRouter } from 'react-router-dom';
init()

function App () {
    const firebase = new Firebase();
    firebaseObserve( firebase )
    return (
        <div className={ style.App }>
            <FirebaseContext.Provider value={ { firebase } }>
                <BrowserRouter>
                    <MainRouter/>
                </BrowserRouter>
            </FirebaseContext.Provider>
        </div>
    );

}

export default App;