import React, { useEffect, useState } from 'react';
import style from './volume-reusable.module.scss';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { useKeyPress } from '../..';

function VolumeReusable ( props ){
    const [ storeVolume, setVolume ] = useState( 0 );
    const [ mouseInColor, setColor ] = useState( props.player.volume*10 );
    const arrowUpPressed = useKeyPress( 'ArrowUp', props.isPlaying );
    const arrowDownPressed = useKeyPress( 'ArrowDown', props.isPlaying );
    function muteVolume ( val ){
        setVolume( props.player.volume )
        props.player.volume = val;
        setColor( val*10 )
    }
    useEffect( ()=>{
        return ()=>{
            setVolume( 0 )
            setColor( 0 )
        }
    },[] )
    function handleVolume( val ){
        props.player.volume = val/10;
        setColor( val )
    }
    useEffect( ()=>{   
        if( arrowUpPressed && props.player.volume <1 ){
            props.player.volume = ( props.player.volume*10+1 )/10
        } else if( arrowDownPressed && props.player.volume > 0 ){
            props.player.volume = ( props.player.volume*10-1 )/10
        }
    },[ arrowDownPressed, arrowUpPressed, props.isPlaying, props.player ] )
    return (
        <>
            <Row className={ style.wrapper } onMouseOut={ ()=> setColor( props.player.volume*10 ) }>
                <Col className={ style.volume_icon } >
                    {
                        props.player.volume > 0?<VolumeUpIcon onClick={ ()=>muteVolume( 0 ) } classes={ {
                            root: style.icon
                        } } />:<VolumeOffOutlinedIcon onClick={ ()=>muteVolume( storeVolume> 0? storeVolume: 0.5 ) } classes={ {
                            root: style.icon } } />
                    }
                </Col>
                {
                    [ ...new Array( 10 ) ].map( ( e,i )=>{
                        return <Col 
                            onClick={ ()=> handleVolume( i+1 ) }
                            onMouseOver={ ()=> setColor( i+1 ) }
                            className={ style.col } 
                            key={ i.toString() }>
                            <span className={ ( i+1 === mouseInColor ) ? style.indicator_active: style.indicator }>{i+1}</span>
                            <span className={ ( i+1 === mouseInColor )? style.bar+ ' '+ style.bar_active:
                                ( i+1 < mouseInColor )? style.bar+ ' '+ style.bar_red:
                                    style.bar }></span>
                        </Col>
                    } )
                }

            </Row>

        </>
    )
}
VolumeReusable.propTypes = {
    player: PropTypes.object,
    isPlaying: PropTypes.bool
}
export default VolumeReusable;